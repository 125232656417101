import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const PrivacyPolicy = ({ data }) => (
  <div className="privacyPolicyPage">
    <Layout>
      <Seo title="Industry" />
      <div className="main_content_wrapper">
        {renderRichText(data.contentfulPrivacyPolicy.content)}
      </div>
    </Layout>
  </div>
)

export default PrivacyPolicy

export const query = graphql`
  query Privacy($language: String) {
    contentfulPrivacyPolicy(node_locale: { eq: $language }) {
      content {
        raw
      }
    }
  }
`
